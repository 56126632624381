<template>
  <div class="main-view">
    <div class="section">
      <div class="filter__wrap">
        <el-button size="small" type="primary" icon="el-icon-plus" @click="showEdit({},0)">创建产品</el-button>
        <el-form ref="elFormDom" inline :model="table.params" size="small">
          <el-form-item prop="goods_name">
            <el-input v-model.trim="table.params.goods_name" placeholder="产品名称">
              <i slot="suffix" class="el-input__icon el-icon-search" @click="onSearch"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="company_id">
            <el-select v-model="table.params.company_id" placeholder="所属机构" @change="onSearch">
              <el-option v-for="item in companyOpt" :key="item.id" :label="item.company_name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="days">
            <el-select v-model="table.params.days" placeholder="活动天数" @change="onSearch">
              <el-option label="1天" :value="1"></el-option>
              <el-option label="2天" :value="2"></el-option>
              <el-option label="3天" :value="3"></el-option>
              <el-option label="4天" :value="4"></el-option>
              <el-option label="5天" :value="5"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div style="display: flex;justify-content: space-between;padding-right:24px;">
        <el-radio-group class="radio_group_wrap" v-model="table.params.type" @change="handleTabs" size="medium">
          <el-radio-button :label="2">审核中({{table.total2}})</el-radio-button>
          <el-radio-button :label="1">已上架({{table.total1}})</el-radio-button>
          <el-radio-button :label="3">已下架({{table.total3}})</el-radio-button>
        </el-radio-group>
      </div>
    </div>
    
    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="curField"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:title="{row}">
        <div class="flex" style="align-items:center;">
          <el-image
            style="width: 80px; height: 40px;border-radius:2px;margin-right:10px"
            :src="row.image"
            fit="cover"
            :preview-src-list="[row.image]"></el-image>
            <div class="table-col-text">{{row.title}}</div>
        </div>
      </template>
      <template v-slot:status="{row}">
        <span :class="`corlor${row.status}`">{{toStr({1:'审核中',2:'已上架',3:'已下架'},row.status)}}</span>
      </template>
      <template v-slot:amount="{row}">
        <span style="color:#FD4100;font-weight: bold;">{{row.amount}}元/人</span>
      </template>
      <template v-slot:action="{row}">
        <!--1-待审核(审核中)；2-审核通过（已上架）；3-已下架  -->
        <template v-if="row.status == 2">
          <el-button type="text" @click='showEdit(row,1)'>复制</el-button>
          <span class="action-btn-line">|</span>
        </template>
        <el-button type="text" @click='showDetail(row)'>详情</el-button> 
        <template v-if="row.status != 1">
          <span class="action-btn-line">|</span>
          <el-button type="text" class="colorDel" @click='handleOperate(row)'>{{row.status == 3 ? '上架' : '下架' }}</el-button>
        </template>
      </template>
    </VTable>

    <edit ref="edit" :companyOpt="companyOpt" @refresh="getTable"></edit>
    <detail ref="detail" @refresh="getTable"></detail>
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue'
import Detail from './components/Detail.vue'
import { mixinTable } from '@/mixins/table.js'
export default {
  name: 'GoodsList',
  components: {
    VTable,
    Edit,
    Detail
  },
  mixins: [mixinTable],
  data() {
    return {
      field1: [
        { name: "title", label: "图文标题", width:'300', hidden: false },
        { name: "company_name", label: "所属机构", showTooltip:true, hidden: false },
        { name: "amount", label: "售价", width:'120', hidden: false },
        { name: "days", label: "活动天数(天)", hidden: false},
        { name: "sales", label: "已售(份)", hidden: false},
        { name: "status", label: "上架状态", hidden: false},
        { name: "check_time", label: "上架时间", width:'160', hidden: false},
        { name: "action", label: "操作", width: "150", hidden: false }
      ],
      field2: [
        { name: "title", label: "图文标题", width:'300', hidden: false },
        { name: "company_name", label: "所属机构", showTooltip:true, hidden: false },
        { name: "amount", label: "售价", width:'120', hidden: false },
        { name: "days", label: "活动天数(天)", hidden: false},
        { name: "sales", label: "已售(份)", hidden: false},
        { name: "status", label: "上架状态", hidden: false},
        { name: "create_time", label: "提交时间", width:'160', hidden: false},
        { name: "action", label: "操作", width: "150", hidden: false }
      ],
      field3: [
        { name: "title", label: "图文标题", width:'300', hidden: false },
        { name: "company_name", label: "所属机构", showTooltip:true, hidden: false },
        { name: "amount", label: "售价", width:'120', hidden: false },
        { name: "days", label: "活动天数(天)", hidden: false},
        { name: "sales", label: "已售(份)", hidden: false},
        { name: "status", label: "上架状态", hidden: false},
        { name: "check_time", label: "下架架时间", width:'160', hidden: false},
        { name: "action", label: "操作", width: "150", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          goods_name: '',
          days: '',
          company_id: '',
          type: 2, //1:上架中 2:待审核 3:已下架
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
        total1: 0, //上架总数
        total2: 0, //审核中总数
        total3: 0 //下架总数
      },
      curField: '',
      companyOpt: [],
    }
  },
  created() {
    this.getTable();
    this.getCompany()
    this.curField = this.field1;
  },
  methods: {
    handleTabs(value) {
      if(value == 1) {
        this.curField = this.field1
      } else if(value == 2) {
        this.curField = this.field2
      } else if(value == 3) {
        this.curField = this.field3
      }
      this.table.params.page = 1;
      this.table.data = [];
      this.getTable();
    },
    // 查询列表
    getTable() {
      this.$http.get('/admin/goods/list', {params: this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list
          this.table.total = res.data.total;
          this.table.total1 = res.data.total1;
          this.table.total2 = res.data.total2;
          this.table.total3 = res.data.total3;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 审核通过的机构
    getCompany() {
      this.$http.get('/admin/company/list', {params: {status:2,page:1,count:10000}}).then(res => {
        if(res.code == 1){
          this.companyOpt = res.data.list;
        }
      })
    },
    showEdit(row, type) {
      console.log(type)
      let dom = this.$refs.edit
      dom.toggle(true)
      dom.getDetail(row, type)
      dom = null
    },
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 上架，下架
    handleOperate(row) {
      let str = row.status == 3 ? '上架' : '下架'
      this.$confirm(`请确认${str}操作？`, "提示", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('/admin/goods/operation', {id: row.id}).then(res => { 
          if(res.code === 1) {
            this.$message.success('操作成功！');
            this.getTable();
          } else {
            this.$message.error(res.msg)
          }
        })
      }).catch(() => {});
    }
  }
}
</script>

<style scoped lang="scss">
.radio_group_wrap {
  margin:0 18px 18px;

  &::v-deep {
    .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      color: #0376E7;
      background-color: #E5F2FE;
      border-color: #0376E7;
    }
  }
}
.corlor1 {
  display: flex;
  align-items: center;
}
.corlor2 {
  display: flex;
  align-items: center;
}
.corlor3 {
  display: flex;
  align-items: center;
}
.corlor1::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #FFA10E;
  display: inline-block;
  margin-right: 5px;
}
.corlor2::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #27D144;
  display: inline-block;
  margin-right: 5px;
}
.corlor3::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #EE5050;
  display: inline-block;
  margin-right: 5px;
}


</style>
