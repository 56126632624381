<template>
  <div div class="addPerson__wrap" v-show="visible">
    <el-header class="shadow page-header__wrap">
      <div class="flex">
        <div class="backBtn" @click="toggle(false)">
          <el-image class="back-img" :src="require('@/assets/img/back.png')"></el-image>
          <span>返回</span>
        </div>
        
        <div class="title">
          <span class="site">当前位置：</span>
          {{ $route.meta.parent }}
          <span> > </span>
          {{ $route.meta.title }}
          <span> > </span>
          新建产品
        </div>
      </div>
    </el-header>

    <div class="form-wrap">
      <el-form
        v-loading="form.loading"
        class="saft_form"
        element-loading-text="加载中…"
        ref="elFormDom"
        label-position="top"
        :model="form.data"
        :rules="form.rules"
        size="small"
        hide-required-asterisk
      >
        <span class="itemTime">产品介绍</span>
        <el-divider></el-divider>
        <el-row :gutter="50" type="flex">
          <el-col :span="8">
            <el-form-item label="" prop="title">
              <template slot="label">产品标题</template>
              <span class="label-hint">（限20字）</span>
              <el-input
                v-model="form.data.title"
                clearable
                placeholder="请输入研学产品标题"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="" prop="open_time">
              <template slot="label">开放时间</template>
              <span class="label-hint">（限20字）</span>
              <el-input
                v-model="form.data.open_time"
                clearable
                placeholder="请输入，如 周一至周五 08:00"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="" prop="period_type">
              <template slot="label">适用学段</template>
              <span class="label-hint">（可多选）</span>
              <el-checkbox-group v-model="form.data.period_type">
                <el-checkbox label="1">小学</el-checkbox>
                <el-checkbox label="2">初中</el-checkbox>
                <el-checkbox label="3">高中</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="days" label="活动天数">
              <el-input-number v-model="form.data.days"  :min="1" :max="5" ></el-input-number>
              <span style="margin-left:10px;">天</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="area_arr" label="所在地区">
              <el-cascader
                style="width:100%;"
                placeholder="请选择省/市/区"
                :popper-append-to-body="false"
                popper-class="areaCascaderWrap"
                v-model="form.data.area_arr"
                :options="areaArr"
                :props="{ checkStrictly: false }">
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="address" label="详细地址">
              <el-input
                v-model="form.data.address"
                clearable
                placeholder="请输入详细地址"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="consult_mobile" label="咨询电话">
              <el-input
                v-model="form.data.consult_mobile"
                clearable
                placeholder="请输入电话号码，以便客户联系咨询"
                maxlength="11"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="company_id" label="所属机构">
              <el-select v-model="form.data.company_id" placeholder="所属机构" filter @change="changeCompany">
                <el-option v-for="item in companyOpt" :key="item.id" :label="item.company_name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="8">
            <el-form-item label="" prop="description">
              <template slot="label">产品描述</template>
              <span class="label-hint">（限100字）</span>
              <el-input
                v-model="form.data.description"
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 4}"
                clearable
                maxlength="100"
                placeholder="产品描述"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="amount" label="售价">
              <el-row :gutter="20">
                <el-col :span="10">
                  <el-input
                    placeholder="请输入金额"
                    v-model="form.data.amount"
                    @input="inputCount">
                    <span slot="suffix">元/人</span>
                  </el-input>
                </el-col>
                <el-col :span="14">
                  <span style="color:#F53030;">实际到账金额：{{factAmount}} </span>
                  <span>元/人</span>
                </el-col>
                <el-col :span="24" style="margin-top:10px; ">
                  <span style="width:360px;position: relative;padding:4px;line-height:18px;font-size: 12px;color: #F53030;border: solid 1px #F53030;border-radius:3px;text-align: center;display: block;">
                    <i class="el-icon-arrow-up" style="position: absolute;top: -9px;right: 35%;color: #F53030;background: #fff;"></i>
                    本平台需抽佣产品售价的{{rate}}%，到账金额已扣除抽佣费，请知悉。
                  </span>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
        <span class="itemTime">产品标签</span>
        <el-divider></el-divider>
        <el-row>
          <el-col :span="20">
            <el-form-item label="" prop="tag_ids">
              <template slot="label">产品标签</template>
              <span class="label-hint">（可多选）</span>
              <el-select v-model="form.data.tag_ids" multiple placeholder="请输入研学产品的标签，合适的标签可以提升曝光率哦~">
                <el-option
                  v-for="item in tagesArr"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <span class="itemTime mt30">产品图片</span>
        <el-divider></el-divider>
        <el-row>
          <el-col :span="8">
            <el-form-item prop="image" label=""  class="imageWrap">
              <template slot="label">主图</template>
              <span class="label-hint" style="left:30px;">（限1张，建议上传尺寸为750*500px的图片）</span>
              <el-upload
                class="upload-demo"
                action=""
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
                :on-remove="handleRemove"
                :http-request="httpRequest"
                accept="image/jpeg, image/png, image/gif">
                <img v-if="form.data.image" :src="form.data.image" style="width:218px;height:146px;border-radius:6px;">
                <img v-else style="width:218px;height:146px;object-fit: cover;" :src="require('@/assets/img/upload3.png')" />
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item prop="images" label=""  class="imageWrap">
              <template slot="label">详情图</template>
              <span class="label-hint" style="left:45px;">（限9张，单张图片大小不超过5M，展示效果为拼接长图）</span>
              <el-upload
                action=""
                :limit="9"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :file-list="fileList"
                :http-request="httpRequestBack"
                accept="image/jpeg, image/png, image/gif">
                <img style="104px;height:104px;object-fit: cover;" :src="require('@/assets/img/upload.png')" />
              </el-upload>
              <el-dialog :visible.sync="dialogVisible" append-to-body>
                <template #title>
                  <div class="el-dialog-title">查看图片</div>
                </template>
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </el-form-item>
          </el-col>
        </el-row>
        <span class="itemTime mt30">其他</span>
        <el-divider></el-divider>
        <el-row>
          <el-col :span="24">
            <el-form-item prop="schedule" label="">
              <template slot="label">行程安排</template>
              <span class="label-hint">（可编辑文字、上传图片、粘贴图片，修改字体大小等，限500字）</span>
              <my-editor ref="editor" :height="300" :editorTxt="this.form.data.schedule"></my-editor>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="30">
          <el-col :span="8">
            <el-form-item prop="deny_refund_day" label="退款须知">
              <div style="border:solid 1px #eee;padding:15px;">
                行程开始前
                <el-input-number v-model="form.data.deny_refund_day" :min="1" :max="10" ></el-input-number>
                <div>可申请退款，<span style="color:#EC3F37;">超时不予退款</span></div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="attention_info" label="">
              <template slot="label">注意事项</template>
              <span class="label-hint">（限200字）</span>
              <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 8}"
                placeholder="请输入购买产品时需要注意的事项，或活动过程中存在特殊情况也需要说明"
                v-model="form.data.attention_info"
                maxlength="200">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="mark" label="">
              <template slot="label">备注</template>
              <span class="label-hint" style="left:35px">（限200字）</span>
              <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 8}"
                placeholder="备注信息，如无可输入“无”"
                v-model="form.data.mark"
                maxlength="200">
              </el-input>
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>
    </div>

    
    <div class="licence_foot">
      <el-button  @click="toggle(false)">取消</el-button>
      <el-button type="primary" @click="submitForm">提交</el-button>
    </div>
   
  </div>
</template>


<script>
import MyEditor from "@/components/MyEditor.vue";
import { REG_EXP } from '@/util'
export default {
  name: 'GoodsEdit',
  props: ['companyOpt'],
  components: {
    MyEditor
  },
  data() {
    return {
      loading: false,
      visible: false,
      form: {
        loading: false,
        data: {
          company_id: '', // 机构id
          title: "", //标题
          description: "", //描述
          open_time: "", //开放时间
          period_type: [], //适用学段：1、小学 2、初中 3、高中，多个用逗号隔开
          amount: '', //费用
          days: 1, //活动天数
          province: '', //注册地址省
          city: '', //注册地址省
          area: '', //注册地址区
          area_arr: [],
          address: "", //地址
          consult_mobile: "", //咨询电话
          allow_refund_day: 1, //行程开始前可退款天数
          deny_refund_day: 1, //行程开始前不可退款天数
          attention_info: "", //注意事项
          mark: "", //备注
          tag_ids: [], //产品标签 多个以,分割
          image: "", //主图
          images: "", //详情图
          schedule: "", //行程安排
          copy_id: '' //复制活动id
        },
        rules: {
          company_id: [{required: true, message: "必填项", trigger: "change"}],
          title: [{required: true, message: "必填项", trigger: "change"}],
          description: [{required: true, message: "必填项", trigger: "change"}],
          open_time: [{required: true, message: "必填项", trigger: "change"}],
          period_type: [{required: true, message: "必填项", trigger: "change"}],
          amount: [{required: true, message: "必填项", trigger: "change"}],
          days: [{required: true, message: "必填项", trigger: "change"}],
          area_arr: [{required: true, message: "必填项", trigger: "change"}],
          address: [{required: true, message: "必填项", trigger: "change"}],
          consult_mobile: [
            {required: true, message: "必填项", trigger: "change"},
            { pattern: REG_EXP.mobilePhone, message: "手机号不正确", trigger: 'blur' }
          ],
          allow_refund_day: [{required: true, message: "必填项", trigger: "change"}],
          deny_refund_day: [{required: true, message: "必填项", trigger: "change"}],
          attention_info: [{required: true, message: "必填项", trigger: "change"}],
          mark: [{required: true, message: "必填项", trigger: "change"}],
          tag_ids: [{required: true, message: "必填项", trigger: "change"}],
          image: [{required: true, message: "必填项", trigger: "change"}],
          images: [{required: true, message: "必填项", trigger: "change"}],
          // account: [
          //   {required: true, message: "请输入账号！", trigger: "change"},
          //   // { pattern: REG_EXP.mobilePhone, message: "手机号不正确", trigger: 'blur' }
          // ],
        }
      },
      areaArr: [],
      tagesArr: [],
      fileList: [],
      dialogVisible: false,
      dialogImageUrl: [],
      factAmount: '---', // 实际到账金额
      currType: 0, // 0:创建，1:复制，2:编辑
      rate: 0, // 机构抽佣比例
    }
  },
  created() {
    this.getArea();
    this.getTage();
  },
  methods: {
    // 详情
    getDetail(row,type) {
      this.currType = type
      if(!!row.id) {
        this.$http.get('/admin/goods/info',{ params:{ id:row.id } }).then(res => {
          if(res.code == 1) {
            let info = res.data;
            Object.keys(info).forEach(item => {
              this.form.data[item] = info[item]
            })
            this.form.data.tag_ids = info.tag_ids.split(",").map(data => { return +data })
            this.form.data.area_arr = [ info.province , info.city , info.area]
            
            if(this.form.data.images.length > 0) {
              this.fileList = []
              this.form.data.images.forEach(v => {
                let str = {
                  name: '',
                  url: v
                }
                this.fileList.push(str)
              })
            }
            this.form.data.copy_id = res.data.id;
            let _rate = this.companyOpt.filter(v => { return v.id == res.data.company_id})
            this.rate = _rate[0].rate;
            this.factAmount = this.form.data.amount - (this.form.data.amount * (this.rate * 0.01))
          }
        })
      }
    },
    reset(done) {
      this.isChange = false
      this.$refs.elFormDom.resetFields()
      this.fileList = [];
      done && done()
    },
    toggle(show) {
      this.visible = show
      !show && this.reset()
    },
    getTage() {
      this.$http.get('/admin/tag/list',{params:{page:1,count:1000}}).then(res => {
        if(res.code == 1) {
          this.tagesArr = res.data.list;
        }
      })
    },
    // 获取省市区
    getArea() {
      this.$http.post('/common/district/all',{}).then(res => {
        if(res.code == 1) {
          this.areaArr = res.data.list;
        }
      })
    },
    // 选择机构获取信息
    changeCompany(value) {
      let _rate = this.companyOpt.filter(v => { return v.id == value})
      this.rate = _rate[0].rate;
      this.factAmount = this.form.data.amount - (this.form.data.amount * (this.rate * 0.01))
    },
    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error('上传文件大小不能超过 5MB!');
      }
      return isLt5M;
    },
    httpRequest(file) {
      let formData = new FormData();
      formData.append('file', file.file);
      this.loading = true;
      this.$http.post('/common/qiniu/upload', {formData, type:'upload'}).then(res => {
        this.form.data.image = res.data.all_path_url;
        this.$refs.elFormDom.clearValidate();
      }).finally(() => {
        this.loading = false;
      })
    },
    
    // 详情图片上传
    httpRequestBack(file) {
      let formData = new FormData();
      formData.append('file', file.file);
      this.$http.post('/common/qiniu/upload', {formData, type:'upload'}).then(res => {
        if(res.code === 1) {
          let str = {
            url: res.data.all_path_url,
            name: file.file.name,
            uid: file.file.uid
          }
          this.fileList.push(str);
          this.form.data.images = res.data.all_path_url;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file) {
      this.fileList.forEach((v,i) => {
        if(file.uid == v.uid) {
          this.fileList.splice(i, 1)
        }
      })
    },
    inputCount() {
      this.factAmount = this.form.data.amount - (this.form.data.amount * (this.rate * 0.01))
    },
    submitForm() {
      this.$refs.elFormDom.validate((valid) => {
        if(valid) {
          this.form.data.schedule = this.$refs.editor.editorContent;
          if (!this.form.data.schedule) {
            this.$message.warning("行程安排不能为空");
            return;
          }
          if(this.fileList.length > 0){
            let _image = []
            this.fileList.forEach(v => {
              _image.push(v.url)
            })
            this.form.data.images = _image.join(",")
          } else {
            this.form.data.images = '';
          }
          let _params = {...this.form.data}
          _params.period_type = this.form.data.period_type.join(",")
          _params.tag_ids = this.form.data.tag_ids.join(",")
          _params.province = this.form.data.area_arr[0];
          _params.city = this.form.data.area_arr[1];
          _params.area = this.form.data.area_arr[2];
          delete _params.area_arr;
          delete _params.tags;
          delete _params.appraise_count;
          delete _params.commit_time;
          delete _params.last_appraise_id;
          delete _params.legal_person;
          delete _params.sales;
          delete _params.score;
          delete _params.score_sum;
          delete _params.update_time;
          delete _params.period_type_str;
          delete _params.tag_str;

          // return
          console.log(this.currType)
          let apiUrl = this.currType == 2 ? '/company/goods/edit' : '/admin/goods/copy'
          this.$http.post(apiUrl, _params).then(res => {
            if(res.code == 1) {
              this.$message.success('创建成功！')
              this.$emit('refresh')
              this.toggle(false)
            }
          })
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
  background-color: #F1F4FC;
  z-index: 5 !important;
  overflow: scroll;
  display: flex;
  flex-direction: column;
}
.page-header__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF;
  color: #595959;

  .title {
    height: 58px;
    line-height: 58px;
  }

  .site {
    color: #898989;
  }
  
  .backBtn {
    height: 30px;
    border: solid 1px #007DF2;
    line-height: 30px;
    padding: 0 9px;
    border-radius: 3px;
    background: #E5F2FE;
    color: #007DF2;
    cursor: pointer;
    margin: 15px 20px 15px 0;
  }
  .back-img {
    width: 18px;
    height: 16px;
    position: relative;
    top: 3px;
    margin-right: 10px;
  }
}
.saft_form {
  padding: 20px;
}
.form-wrap {
  background: #fff;
  border-radius: 5px;
  margin: 20px;
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
}
.itemTime {
  display: block;
}
.itemTime::before {
  content: "";
  width: 5px;
  height: 15px;
  background: #409EFF;
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 2px;
}



</style>
<style scoped>
.imageWrap ::v-deep .el-upload--picture-card {
  border: none;
  width: 104px;
  height: 104px;
}
.imageWrap ::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 104px;
  height: 104px;
}
.imageWrap ::v-deep .el-range-editor--small .el-range-separator {
  width: 20px;
}
.licence_foot {
  background: #fff;
  padding: 5px 20px;
  margin-top: auto;
  text-align: right;
}
</style>